import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-56ea816b"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "school-type-box common-flex-direction-center"
};
const _hoisted_2 = {
  class: "common-title wow slideInUp"
};
const _hoisted_3 = {
  class: "common-des wow slideInUp"
};
const _hoisted_4 = {
  class: "common-detail wow slideInUp"
};
const _hoisted_5 = {
  class: "content"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = ["data-wow-delay"];
const _hoisted_8 = ["src"];
const _hoisted_9 = {
  class: "item-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($data.title), 1), _createElementVNode("div", _hoisted_3, _toDisplayString($data.des), 1), _createElementVNode("div", _hoisted_4, _toDisplayString($data.detail), 1), _createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
    class: _normalizeClass(["wow flipInY", $props.type == 3 ? 'help-image' : 'content-image']),
    src: $data.image
  }, null, 10, _hoisted_6), _createElementVNode("div", {
    class: _normalizeClass(["content-right", $props.type == 3 ? 'help-right' : 'content-right'])
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["wow zoomIn", $props.type == 3 ? 'help-item' : 'content-item']),
      "data-wow-delay": index * 0.05 + 's',
      key: index
    }, [_createElementVNode("img", {
      class: "item-image",
      src: item.image
    }, null, 8, _hoisted_8), _createElementVNode("div", _hoisted_9, _toDisplayString(item.title), 1)], 10, _hoisted_7);
  }), 128))], 2)])]);
}