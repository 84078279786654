import WOW from "wow.js";
export default {
  props: {
    type: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      image: require('../static/school-take-out/content-2/11.png'),
      title: '校园外卖服务',
      des: '搭建平台整合校园周边商家，为师生提供外卖订餐配送服务',
      detail: '合伙人团队借助本应用系统快速搭建类似美团外卖、饿了么的简易版校园外卖订餐系统，学生通过公众号/小程序/APP等进入，自动定位并选择周边餐厅，选餐下单或到店自取，在线支付/ 会员卡支付 / 货到付款。为本校师生提供便捷的外卖订餐方案同时，也为本校创业实践学生或勒工助学同学提供兼职实习机会',
      list: [{
        image: require('../static/school-take-out/content-2/1.png'),
        title: '大排档饭店'
      }, {
        image: require('../static/school-take-out/content-2/2.png'),
        title: '点心'
      }, {
        image: require('../static/school-take-out/content-2/3.png'),
        title: '烧烤'
      }, {
        image: require('../static/school-take-out/content-2/4.png'),
        title: '快递物流'
      }, {
        image: require('../static/school-take-out/content-2/5.png'),
        title: '夜宵'
      }, {
        image: require('../static/school-take-out/content-2/6.png'),
        title: '砂锅粥'
      }, {
        image: require('../static/school-take-out/content-2/7.png'),
        title: '酸辣麻辣烫'
      }, {
        image: require('../static/school-take-out/content-2/8.png'),
        title: '奶茶小吃'
      }, {
        image: require('../static/school-take-out/content-2/9.png'),
        title: '医疗药品'
      }, {
        image: require('../static/school-take-out/content-2/10.png'),
        title: '超市'
      }]
    };
  },

  created() {
    if (this.type == 2) {
      this.image = require('../static/school-run/content-2/11.png');
      this.title = '跑腿特色功能，满足平台多样需求';
      this.des = '服务有温度，跑腿有速度';
      this.detail = '认证成为“骑手”的本校兼职学生抢单送单，任务单状态实时推送，任务收益实时入账。校内学生兼职的运力众包，开心领跑快乐赚零钱！专为配送团队提供的配送调度系统，接口自动接收商户在各大系统的外卖订单，自动发单入抢单群或指派到人。智能/人工调度双模式，人工录单/扫码收单双通道，地图定位，来单响铃，转单接力，统计结算。';
      this.list = [{
        image: require("../static/school-run/content-2/1.png"),
        title: "大排档饭店"
      }, {
        image: require("../static/school-run/content-2/2.png"),
        title: "点心"
      }, {
        image: require("../static/school-run/content-2/3.png"),
        title: "烧烤"
      }, {
        image: require("../static/school-run/content-2/4.png"),
        title: "快递物流"
      }, {
        image: require("../static/school-run/content-2/5.png"),
        title: "夜宵"
      }, {
        image: require("../static/school-run/content-2/6.png"),
        title: "砂锅粥"
      }, {
        image: require("../static/school-run/content-2/7.png"),
        title: "酸辣麻辣烫"
      }, {
        image: require("../static/school-run/content-2/8.png"),
        title: "奶茶小吃"
      }, {
        image: require("../static/school-run/content-2/9.png"),
        title: "医疗药品"
      }, {
        image: require("../static/school-run/content-2/10.png"),
        title: "超市"
      }];
    } else if (this.type == 3) {
      this.image = require('../static/school-help/content-2/7.png');
      this.title = '校园社交圈子/校园分类信息平台';
      this.des = '打造校园社交圈子(如论坛、爆料、问答、朋友圈、信息流等）搭建二手交易平台、表白墙、寻物启事和校园兼职平台等';
      this.detail = '每年一轮毕业季，生活学习用品大交易；学生自主发布二手交易，设定有效时间。电话QQ等联系方式加密显示，充分保护用户隐私。交易状态随时反馈，顶/踩功能帮助辨别，智能算法可信度评估。还支持学生闲置物品共享租赁，让校园物资流动起来。管理员自定义信息栏目、筛选条件、广告位招商展示等。';
      this.list = [{
        image: require('../static/school-help/content-2/1.png'),
        title: '发表白'
      }, {
        image: require('../static/school-help/content-2/2.png'),
        title: '发闲物'
      }, {
        image: require('../static/school-help/content-2/3.png'),
        title: '发任务'
      }, {
        image: require('../static/school-help/content-2/4.png'),
        title: '发资讯'
      }, {
        image: require('../static/school-help/content-2/5.png'),
        title: '发寻物'
      }, {
        image: require('../static/school-help/content-2/6.png'),
        title: '游戏陪玩'
      }];
    }
  },

  mounted() {
    let wow = new WOW({
      boxClass: 'wow',
      //需要执行动画元素的Class
      animateClass: 'animated',
      //animation.css动画的Class
      offset: 0,
      //距离可视区域多少开始执行动画
      mobile: true,
      //是否在移动设备执行动画
      live: true //异步加载的内容是否有效

    });
    wow.init();
  }

};