import WOW from "wow.js";
export default {
  data() {
    return {
      siteInfo: window.siteInfo,
      content3ActiveIndex: 0,
      content3Tab: ['迪想校园用户端', '迪想校园商家端', '迪想校园骑手端'],
      content3List: [{
        image: require('../../static/download-app/buyer.png'),
        title: '迪想校园用户端',
        des: 'H5与小程序多个用户入口，全方位满足用户下单需求',
        qrcode: require('../../static/common/miniapp-code.png'),
        codeText: '用户端二维码'
      }, {
        image: require('../../static/download-app/business.png'),
        title: '迪想校园商家端',
        des: '来单响铃提醒，快速接单、发单，上传商品、设置店铺，手机端和PC端便捷操作。',
        qrcode: require('../../static/common/mp-code.png'),
        codeText: '关注公众号，下载商家端APP'
      }, {
        image: require('../../static/download-app/rider.png'),
        title: '迪想校园骑手端',
        des: '智能派遣、及时推送、全程跟进，助力骑手顺利接单送单',
        qrcode: require('../../static/common/mp-code.png'),
        codeText: '关注公众号，下载骑手端APP'
      }]
    };
  },

  mounted() {
    let wow = new WOW({
      boxClass: 'wow',
      //需要执行动画元素的Class
      animateClass: 'animated',
      //animation.css动画的Class
      offset: 0,
      //距离可视区域多少开始执行动画
      mobile: true,
      //是否在移动设备执行动画
      live: true //异步加载的内容是否有效

    });
    wow.init();
  },

  methods: {
    mouseoverTab(index) {
      this.content3ActiveIndex = index;
    }

  }
};