// import { ref, reactive } from 'vue'
import { apiGetZones, apiApply } from "@/api/api-list";
import { ElNotification } from 'element-plus';
export default {
  data() {
    return {
      dialogFormVisible: false,
      provinceList: [],
      cityList: [],
      districtList: [],
      ruleForm: {
        province: '',
        city: '',
        district: '',
        address: '',
        identity: '',
        phone: '',
        name: '',
        zone_id: ''
      },
      rules: {
        province: [{
          required: true,
          message: '请选择省',
          trigger: 'blur'
        }],
        city: [{
          required: true,
          message: '请选择市',
          trigger: 'blur'
        }],
        district: [{
          required: true,
          message: '请选择区',
          trigger: 'blur'
        }],
        address: [{
          required: true,
          message: '请输入申请地区',
          trigger: 'blur'
        }],
        identity: [{
          required: true,
          message: '请选择您的身份',
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          message: '请填写您的联系方式',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请填写您的姓名',
          trigger: 'blur'
        }]
      },
      loading: false
    };
  },

  methods: {
    getZones(type, id, isOne = false) {
      apiGetZones({
        pid: id
      }).then(res => {
        console.log('res', res);

        if (res.status == 1) {
          if (type == 'province') {
            this.provinceList = res.data;

            if (isOne) {
              this.dialogFormVisible = true;
            }
          } else if (type == 'city') {
            this.cityList = res.data;
          } else if (type == 'district') {
            this.districtList = res.data;
          }
        } else {
          ElNotification({
            title: 'Error',
            message: res.msg,
            type: 'error'
          });
        }
      });
    },

    showDialog() {
      console.log('this.provinceList', this.provinceList.length);

      if (!this.provinceList.length) {
        this.getZones('province', 0, true);
      } else {
        this.dialogFormVisible = true;
      }
    },

    provinceChange(name) {
      let provinceInfo = this.forEachList('province', name);
      console.log("省信息", provinceInfo);
      this.ruleForm.province = provinceInfo.name;
      this.ruleForm.city = '';
      this.ruleForm.district = '';
      this.districtList = [];
      this.districtInfo = {};
      this.cityList = [];
      this.getZones('city', provinceInfo.id);
    },

    cityChange(name) {
      let cityInfo = this.forEachList('city', name);
      console.log("市信息", cityInfo);
      this.ruleForm.city = name;
      this.ruleForm.district = '';
      this.districtList = [];
      this.districtInfo = {};
      this.getZones('district', cityInfo.id);
    },

    districtChange(name) {
      let districtInfo = this.forEachList('district', name);
      console.log("区信息", districtInfo);
      this.ruleForm.district = name;
      this.ruleForm.zone_id = districtInfo.id;
    },

    forEachList(type, name) {
      let list = [];

      if (type == 'province') {
        list = this.provinceList;
      } else if (type == 'city') {
        list = this.cityList;
      } else if (type == 'district') {
        list = this.districtList;
      }

      for (let i = 0; i < list.length; i++) {
        if (list[i].name == name) {
          return list[i];
        }
      }
    },

    hideDialog() {
      this.dialogFormVisible = false;
    },

    submitForm() {
      this.$refs.ruleFormRef.validate(valid => {
        console.log('valid', valid);

        if (!valid) {
          return false;
        }

        this.loading = true;
        apiApply(this.ruleForm).then(res => {
          this.loading = false;

          if (res.status == 1) {
            this.hideDialog();
            ElNotification({
              title: '成功',
              message: res.data,
              type: 'success'
            });
          } else {
            ElNotification({
              title: '错误',
              message: res.msg,
              type: 'error'
            });
          }
        }).catch(err => {
          this.loading = false;
          ElNotification({
            title: '错误',
            message: err,
            type: 'error'
          });
        });
      });
    }

  }
};