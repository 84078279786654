import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "header-box"
};
const _hoisted_2 = {
  class: "header-box_right"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "header-box_left"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  key: 0
};
const _hoisted_7 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");

  const _component_Application = _resolveComponent("Application");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_router_link, {
    to: "/"
  }, {
    default: _withCtx(() => [$props.siteInfo.logo != '' ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      src: $props.siteInfo.logo,
      alt: "",
      class: "image"
    }, null, 8, _hoisted_3)) : _createCommentVNode("", true)]),
    _: 1
  })]), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leftList, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: _normalizeClass([$props.activeIndex == index ? 'active' : '', "header-box_left__item"])
    }, [_createElementVNode("div", {
      class: _normalizeClass(item.name == '站长申请' ? 'item-btn' : ''),
      onClick: $event => _ctx.showDialog(item.name)
    }, [item.name == '站长申请' ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(item.name), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_router_link, {
      to: item.link
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
      _: 2
    }, 1032, ["to"])]))], 10, _hoisted_5)], 2);
  }), 128))]), _createVNode(_component_Application, {
    ref: "application"
  }, null, 512)]);
}