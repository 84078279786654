import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-b2ed63b2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "master-case-box header-top"
};
const _hoisted_2 = {
  class: "master-case-box-content"
};
const _hoisted_3 = {
  class: "content-1-box common-flex-direction-center"
};
const _hoisted_4 = {
  class: "content"
};
const _hoisted_5 = ["data-wow-delay", "onMouseenter", "onMouseleave"];
const _hoisted_6 = {
  class: "common-style default"
};
const _hoisted_7 = {
  class: "item-box-left"
};
const _hoisted_8 = {
  class: "item-title"
};
const _hoisted_9 = {
  class: "item-des"
};
const _hoisted_10 = {
  class: "common-style active"
};
const _hoisted_11 = {
  class: "item-box-left"
};
const _hoisted_12 = {
  class: "item-title"
};
const _hoisted_13 = {
  class: "item-des"
};
const _hoisted_14 = {
  class: "item-box-right"
};
const _hoisted_15 = ["src"];
const _hoisted_16 = {
  class: "content-2-box common-flex-direction-center"
};
const _hoisted_17 = {
  class: "content"
};
const _hoisted_18 = ["data-wow-delay"];
const _hoisted_19 = {
  class: "item-des-before"
};
const _hoisted_20 = {
  class: "item-title"
};
const _hoisted_21 = {
  class: "item-des-after"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Header = _resolveComponent("Header");

  const _component_ImageTop = _resolveComponent("ImageTop");

  const _component_Footer = _resolveComponent("Footer");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Header, {
    activeIndex: 5,
    siteInfo: $data.siteInfo
  }, null, 8, ["siteInfo"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_ImageTop), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.content1List, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "item-box wow fadeInUp",
      "data-wow-delay": index * 0.1 + 's',
      key: index,
      onMouseenter: $event => $options.mouseenter(item),
      onMouseleave: $event => $options.mouseLeave(item)
    }, [_createVNode(_Transition, {
      name: "fade"
    }, {
      default: _withCtx(() => [_withDirectives(_createElementVNode("div", _hoisted_6, [_withDirectives(_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_9, _toDisplayString(item.des), 1)], 512), [[_vShow, !item.showImage]])], 512), [[_vShow, !item.showImage]])]),
      _: 2
    }, 1024), _withDirectives(_createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_13, _toDisplayString(item.des), 1)]), _createVNode(_Transition, {
      name: "move-left"
    }, {
      default: _withCtx(() => [_withDirectives(_createElementVNode("div", _hoisted_14, [_createElementVNode("img", {
        class: "image",
        src: item.image,
        alt: ""
      }, null, 8, _hoisted_15)], 512), [[_vShow, item.showImage]])]),
      _: 2
    }, 1024)], 512), [[_vShow, item.showImage]])], 40, _hoisted_5);
  }), 128))])]), _createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.content2List, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "item-box wow fadeInUp",
      "data-wow-delay": index * 0.1 + 's',
      key: index
    }, [_createElementVNode("div", _hoisted_19, _toDisplayString(item.desBefore), 1), _createElementVNode("div", _hoisted_20, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_21, _toDisplayString(item.desAfter), 1)], 8, _hoisted_18);
  }), 128))])])]), _createVNode(_component_Footer, {
    siteInfo: $data.siteInfo
  }, null, 8, ["siteInfo"])]);
}