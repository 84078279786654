export default {
  data() {
    return {// siteInfo: window.siteInfo
    };
  },

  props: {
    siteInfo: Object
  },
  watch: {
    siteInfo: {
      handler(val) {},

      immediate: true
    }
  }
};