import WOW from "wow.js";
export default {
  mounted() {
    let wow = new WOW({
      boxClass: 'wow',
      //需要执行动画元素的Class
      animateClass: 'animated',
      //animation.css动画的Class
      offset: 0,
      //距离可视区域多少开始执行动画
      mobile: true,
      //是否在移动设备执行动画
      live: true //异步加载的内容是否有效

    });
    wow.init();
  },

  data() {
    return {
      siteInfo: window.siteInfo,
      content1List: [{
        title: '48+',
        des: '应用插件',
        showImage: false,
        image: require('../../static/master-case/content-1/1.png')
      }, {
        title: '40W+',
        des: '商业客户',
        showImage: false,
        image: require('../../static/master-case/content-1/1.png')
      }, {
        title: '7*24H',
        des: '优质服务',
        showImage: false,
        image: require('../../static/master-case/content-1/1.png')
      }],
      content2List: [{
        desBefore: '覆盖',
        title: '50+',
        desAfter: '区域'
      }, {
        desBefore: '合伙人',
        title: '25',
        desAfter: '大服务'
      }]
    };
  },

  methods: {
    mouseenter(item) {
      item.showImage = true;
    },

    mouseLeave(item) {
      item.showImage = false;
    }

  }
};