import WOW from "wow.js";
export default {
  mounted() {
    let wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true //异步加载的内容是否有效

    });
    wow.init();
  },

  data() {
    return {
      siteInfo: window.siteInfo
    };
  }

}; // #endregion 变量