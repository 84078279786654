import WOW from "wow.js";
export default {
  mounted() {
    let wow = new WOW({
      boxClass: 'wow',
      //需要执行动画元素的Class
      animateClass: 'animated',
      //animation.css动画的Class
      offset: 0,
      //距离可视区域多少开始执行动画
      mobile: true,
      //是否在移动设备执行动画
      live: true //异步加载的内容是否有效

    });
    wow.init();
  },

  data() {
    return {
      siteInfo: window.siteInfo,
      content4List: [{
        image: require('../../static/school-help/content-3/1.png'),
        title: '全面的数据分析统计访问量',
        des: '访问量、各项收入及时跟踪'
      }, {
        image: require('../../static/school-help/content-3/2.png'),
        title: '服务支持',
        des: '客服全天候服务，专业运营人员提供方案支持'
      }, {
        image: require('../../static/school-help/content-3/3.png'),
        title: '技术支持',
        des: '稳定的系统，强大的安全防御，高弹性的扩展能力'
      }, {
        image: require('../../static/school-help/content-3/4.png'),
        title: '个性化自定义首页',
        des: '分类丰富，支持自定义首页组件自由搭配'
      }]
    };
  }

};