import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-4636f3ac"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "image-top-box"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "des wow slideInLeft"
};
const _hoisted_4 = {
  class: "title wow slideInLeft"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString($data.des), 1), _createElementVNode("div", _hoisted_4, _toDisplayString($data.title), 1)])]);
}