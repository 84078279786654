import WOW from "wow.js";
export default {
  data() {
    return {
      siteInfo: window.siteInfo,
      advantageList: [{
        image: require("../../static/partner/content-1/1.png"),
        name: "亿万级市场规模优秀市场开拓能力"
      }, {
        image: require("../../static/partner/content-1/2.png"),
        name: "跟踪式服务教学精细化运营指导"
      }, {
        image: require("../../static/partner/content-1/3.png"),
        name: "全阶段发展规划助力站点高效运转"
      }, {
        image: require("../../static/partner/content-1/4.png"),
        name: "专业客服对接运营团队培训"
      }, {
        image: require("../../static/partner/content-1/5.png"),
        name: "统一的品牌媒体推广"
      }],
      profitModelList: [{
        image: require("../../static/partner/content-2/1.png"),
        name: "本地外卖商家营业额抽成（10%-20%）"
      }, {
        image: require("../../static/partner/content-2/2.png"),
        name: "打造线下线上门店互通互联。通过线上展示，提高盈利能力"
      }, {
        image: require("../../static/partner/content-2/3.png"),
        name: "搭建配送团队，通过配送服务收取配送费"
      }, {
        image: require("../../static/partner/content-2/4.png"),
        name: "区域内广告投放，店铺活动投放，店铺竞价排名"
      }, {
        image: require("../../static/partner/content-2/5.png"),
        name: "跑腿服务盈利，自由选择跑腿内容"
      }, {
        image: require("../../static/partner/content-2/6.png"),
        name: "通过大流量入口，发展其他校园生态变现渠道"
      }],
      supportList: [{
        image: require("../../static/partner/content-3/1.png"),
        name: "培训支持"
      }, {
        image: require("../../static/partner/content-3/2.png"),
        name: "政策扶持"
      }, {
        image: require("../../static/partner/content-3/3.png"),
        name: "资质保障"
      }, {
        image: require("../../static/partner/content-3/4.png"),
        name: "技术支持"
      }, {
        image: require("../../static/partner/content-3/5.png"),
        name: "资源支持"
      }, {
        image: require("../../static/partner/content-3/6.png"),
        name: "售后服务"
      }, {
        image: require("../../static/partner/content-3/7.png"),
        name: "推广支持"
      }, {
        image: require("../../static/partner/content-3/8.png"),
        name: "销售方案"
      }, {
        image: require("../../static/partner/content-3/9.png"),
        name: "美工支持"
      }, {
        image: require("../../static/partner/content-3/10.png"),
        name: "运营支持"
      }],
      conditionList: [{
        image: require("../../static/partner/content-4/1.png"),
        name: "任何在校大学生，或者社会人士均可，但是必须能够投入足够的精力跟时间"
      }, {
        image: require("../../static/partner/content-4/2.png"),
        name: "具有健全团队配置，有健全的团队配置和强劲的市场开拓能力"
      }, {
        image: require("../../static/partner/content-4/3.png"),
        name: "看好迪想校园共享经济未来"
      }],
      flowList: [{
        image: require("../../static/partner/content-5/1.png"),
        title: "1.提交申请",
        des: "线上提交申请 介绍团队情况 标注合作地区"
      }, {
        image: require("../../static/partner/content-5/2.png"),
        title: "2.电话沟通",
        des: "介绍合作详情 沟通合作细则 确定合作意向"
      }, {
        image: require("../../static/partner/content-5/3.png"),
        title: "3.双方考察",
        des: "考察公司实力 审查合作团队"
      }, {
        image: require("../../static/partner/content-5/4.png"),
        title: "4.确认合作",
        des: "电子签约合同 确定合作 发送相关资料"
      }, {
        image: require("../../static/partner/content-5/5.png"),
        title: "5.搭建站点",
        des: "搭建校区站点 运营团队培训 开业前期筹备"
      }]
    };
  },

  mounted() {
    let wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true //异步加载的内容是否有效

    });
    wow.init();
  }

};