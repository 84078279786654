import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "footer-box"
};
const _hoisted_2 = {
  class: "margin-top-10"
};
const _hoisted_3 = {
  class: "margin-top-10"
};
const _hoisted_4 = {
  href: "https://beian.miit.gov.cn/#/Integrated/index",
  style: {
    "margin-left": "10px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, _toDisplayString($props.siteInfo.describe), 1), _createElementVNode("div", _hoisted_2, _toDisplayString($props.siteInfo.support), 1), _createElementVNode("div", _hoisted_3, [_createTextVNode(" ©2023 " + _toDisplayString($props.siteInfo.title) + " 版权所有 ", 1), _createElementVNode("a", _hoisted_4, _toDisplayString($props.siteInfo.beian), 1)])]);
}