import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-ef4e450a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "school-take-out-box header-top"
};
const _hoisted_2 = {
  class: "school-take-out-box_content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Header = _resolveComponent("Header");

  const _component_SchoolHeader = _resolveComponent("SchoolHeader");

  const _component_Advantage = _resolveComponent("Advantage");

  const _component_SchoolType = _resolveComponent("SchoolType");

  const _component_EachSideShow = _resolveComponent("EachSideShow");

  const _component_Footer = _resolveComponent("Footer");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Header, {
    activeIndex: 2,
    siteInfo: $data.siteInfo
  }, null, 8, ["siteInfo"]), _createVNode(_component_SchoolHeader, {
    type: 1
  }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_Advantage, {
    type: 1
  }), _createVNode(_component_SchoolType, {
    type: 1
  }), _createVNode(_component_EachSideShow)]), _createVNode(_component_Footer, {
    siteInfo: $data.siteInfo
  }, null, 8, ["siteInfo"])]);
}