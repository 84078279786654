import WOW from "wow.js";
export default {
  props: {
    type: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      list: [{
        title: "品类多",
        des: "商家品类多，校内食堂、校外商家均可下单，水果、超市、零食也可下单",
        image: require("../static/school-take-out/content-1/1.png")
      }, {
        title: "配送快",
        des: "配送速度快，校园中转站模式，30分钟送达",
        image: require("../static/school-take-out/content-1/2.png")
      }, {
        title: "可兼职",
        des: "所有外卖全部由兼职学生配送到宿舍，避免社会化外卖平台只能送到校门口或宿舍楼下的问题",
        image: require("../static/school-take-out/content-1/3.png")
      }]
    };
  },

  created() {
    if (this.type == 2) {
      this.list = [{
        title: "易传播、易推广、易营销",
        des: "",
        image: require("../static/school-help/content-1/1.png")
      }, {
        title: "符合校园市场需求，多需求推广传播，多种渠道获取信息",
        des: "",
        image: require("../static/school-help/content-1/2.png")
      }, {
        title: "种类丰富，招聘兼职、寻物启事、表白墙、二手市场、任务悬赏、资讯",
        des: "",
        image: require("../static/school-help/content-1/3.png")
      }, {
        title: "发布信息更加轻盈化、更方便、随时随地",
        des: "",
        image: require("../static/school-help/content-1/4.png")
      }, {
        title: "服务更方便、主要服务于校内师生",
        des: "",
        image: require("../static/school-help/content-1/5.png")
      }];
    }
  },

  mounted() {
    let wow = new WOW({
      boxClass: 'wow',
      //需要执行动画元素的Class
      animateClass: 'animated',
      //animation.css动画的Class
      offset: 0,
      //距离可视区域多少开始执行动画
      mobile: true,
      //是否在移动设备执行动画
      live: true //异步加载的内容是否有效

    });
    wow.init();
  },

  methods: {
    mouseoverTab(index) {
      console.log("index", index);
      this.activeIndex = index;
    }

  }
};