import WOW from "wow.js";
import { ref } from 'vue';
export default {
  setup: () => {
    // # child 变量名 需要同 组件上的ref 名称相同
    const application = ref();

    const showDialog = () => {
      // 调用子组件的方法
      // # 需要子组件通过setup 暴露 childMethod 方法
      application.value.showDialog();
    }; // # 原理 就是 通过ref 能直接访问 子组件dom
    // # 子组件暴露的方法 能从dom中直接调用
    // # 暴露子组件和 handleClick 方法


    return {
      application,
      showDialog
    };
  },

  mounted() {
    let wow = new WOW({
      boxClass: 'wow',
      //需要执行动画元素的Class
      animateClass: 'animated',
      //animation.css动画的Class
      offset: 0,
      //距离可视区域多少开始执行动画
      mobile: true,
      //是否在移动设备执行动画
      live: true //异步加载的内容是否有效

    });
    wow.init();
  }

};