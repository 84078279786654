import { createRouter, createWebHistory } from 'vue-router'

// 引入页面
import IndexView from '../views/index/index.vue' // 官方首页
import diyPage from '../views/index/diyPage.vue' // 自定义页面
import PartnerIndexView from '../views/partner/index.vue'
import SchoolTakeOutIndexView from '../views/school-take-out/index.vue'
import SchoolRunIndexView from '../views/school-run/index.vue'
import SchoolHelpIndexView from '../views/school-help/index.vue'
import MasterCaseIndexView from '../views/master-case/index.vue'
import aboutUsIndex from '../views/about-us/index.vue'
import downloadAppIndex from '../views/download-app/index.vue'

// 取出域名
// var domain = window.location.host
// window.sub_domain = 'index';
// if (domain.indexOf('.i.') > -1) {
//   var temp = domain.split('.i.')
//   window.sub_domain = temp[0]; // 如：xzft.i.jieyang.la 中的 xzft
//
// } else if (domain.indexOf('jieyang.la') === -1) {
//   window.sub_domain = domain;
// }
// console.log('sub_domain: ', sub_domain)

// - - - - (TODO 新增网站必改) - - - -
// var siteInfo = {
//   // 官网
//   "www.ezu.la": {
//     title: '迪想校园',
//     describe: '揭阳市揭东区精易科技有限公司',
//     support: '技术支持：service@125.la',
//     logo: require('../static/header/header-right.png'),
//     beian: '粤ICP备12094385号'
//   },
//   // 小镇飞兔
//   xzft: {
//     title: '小镇飞兔',
//     describe: '西丰县小城飞兔网络科技有限公司',
//     support: '技术支持：2270004030@qq.com',
//     logo: require('../static/header/logo-xzft.png'),
//     beian: '粤ICP备12094385号'
//   },
//   // 小镇飞兔
//   'xiaozhenfeitu.cn': {
//     title: '小镇飞兔',
//     describe: '西丰县小城飞兔网络科技有限公司',
//     support: '技术支持：2270004030@qq.com',
//     logo: require('../static/header/logo-xzft.png'),
//     beian: '辽ICP备2023006857号'
//   },
//   // 喜乐道真
//   xldz: {
//     title: '喜乐外卖',
//     describe: '贵州新淘百网络科技有限公司',
//     support: '技术支持：149704935@qq.com',
//     logo: require('../static/header/logo-xldz.png'),
//     beian: '粤ICP备12094385号'
//   },
//   // 百搭美食
//   bdms: {
//     title: '百搭美食',
//     describe: '河源市百搭外卖有限公司',
//     support: '',
//     logo: require('../static/header/logo-bdms.jpg'),
//     beian: '粤ICP备12094385号'
//   },
//   // 百搭美食
//   mmjs: {
//     title: '米米集市',
//     describe: '长沙米米集市网络科技有限公司',
//     support: '',
//     logo: require('../static/header/logo-mmjs.png'),
//     beian: '粤ICP备12094385号'
//   }
// }
// if (!siteInfo[sub_domain]) {
//   window.sub_domain = 'www.ezu.la';
// }

// 默认网站信息，在读取不到信息时使用
window.defaultSiteInfo = {
  title: '迪想校园',
  describe: '揭阳市揭东区精易科技有限公司',
  support: '技术支持：service@125.la',
  logo: require('../static/header/header-right.png'),
  beian: '粤ICP备12094385号'
}

// 首页
var indexPage = {
  path: '/',
  name: 'index',
  component: IndexView,
  meta: {
    title: '迪想校园'
  }
}

// 官网
if (window.location.host == 'www.ezu.la') {
  console.log('迪想校园官网')
  window.domain = 'www.ezu.la';
  window.siteInfo = window.defaultSiteInfo;

  // 自定义页面
} else {
  console.log(window.location.host)
  window.siteInfo = {
    title: '加载中...',
    describe: '加载中...',
    support: '加载中...',
    logo: '',
    beian: '加载中...'
  }
  indexPage.component = diyPage
}

const routes = [
  indexPage,
  {
    path: '/partner',
    name: 'partnerIndex',
    component: PartnerIndexView,
    meta: {
      title: '合伙人计划'
    }
  },
  {
    path: '/school-take-out',
    name: 'schoolTakeOutIndex',
    component: SchoolTakeOutIndexView,
    meta: {
      title: '校园外卖'
    }
  },
  {
    path: '/school-run',
    name: 'schoolRunIndex',
    component: SchoolRunIndexView,
    meta: {
      title: '校园跑腿'
    }
  },
  {
    path: '/school-help',
    name: 'schoolHelpIndex',
    component: SchoolHelpIndexView,
    meta: {
      title: '校园生活'
    }
  },
  {
    path: '/master-case',
    name: 'masterCaseIndex',
    component: MasterCaseIndexView,
    meta: {
      title: '站长案例'
    }
  },
  {
    path: '/about-us',
    name: 'aboutUsIndex',
    component: aboutUsIndex,
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '/download-app',
    name: 'downloadAppIndex',
    component: downloadAppIndex,
    meta: {
      title: '用户体验'
    }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;
