import AMap from 'AMap';
import WOW from "wow.js";
let map = null;
let marker = null;
let infoWindow = null;
export default {
  data() {
    return {
      siteInfo: window.siteInfo,
      showTopImage: false,
      tabList: ['公司简介', '企业文化', '发展历程', '联系我们'],
      tabIndex: 0,
      slideList: [require('../../static/about-us/content-1/1.png'), require('../../static/about-us/content-1/2.png'), require('../../static/about-us/content-1/3.png'), require('../../static/about-us/content-1/4.png')],
      content2List: [{
        title: '愿望',
        des: '有影响力的互联网企业'
      }, {
        title: '使命',
        des: '助力大学生成长、成才、成功'
      }, {
        title: '价值观',
        des: '没有愿景的企业做不大，没有情怀的创业者走不远'
      }],
      content3List: [{
        title: "2013年9月",
        des: "精易科技有限公司成立"
      }, {
        title: "2017年3月",
        des: "外卖跑腿系统1.0版本正式上线"
      }, {
        title: "2018年2月",
        des: "开始面向城镇站点部署oem系统"
      }, {
        title: "2019年5月",
        des: "全国多个城市站点稳定运行"
      }, {
        title: "2021年8月",
        des: "全国合作站点近百个，遍布各大城市乡镇及校园"
      }, {
        title: "2022年6月",
        des: "正式出台《迪想校园高校疫情解决简案》助力高校疫情防疫"
      }],
      content4List: [{
        title: '刘经理：157-66156-125',
        image: require('../../static/about-us/content-2/liu-code.png')
      }, {
        title: '黄经理：177-2218-4970',
        image: require('../../static/about-us/content-2/huang-code.png')
      }, {
        title: '许经理：189-3369-0157',
        image: require('../../static/about-us/content-2/xu-code.png')
      }]
    };
  },

  mounted() {
    setTimeout(() => {
      this.showTopImage = true;
    }, 100);
    setTimeout(() => {
      this.initMap();
    }, 2000);
    let wow = new WOW({
      boxClass: 'wow',
      //需要执行动画元素的Class
      animateClass: 'animated',
      //animation.css动画的Class
      offset: 0,
      //距离可视区域多少开始执行动画
      mobile: true,
      //是否在移动设备执行动画
      live: true //异步加载的内容是否有效

    });
    wow.init();
  },

  methods: {
    changeTab(index) {
      if (this.tabIndex == index) {
        return;
      }

      this.tabIndex = index;

      if (index == 3) {
        setTimeout(() => {
          this.initMap();
        }, 100);
      }
    },

    initMap() {
      map = new AMap.Map('container', {
        zoom: 16,
        //级别
        center: [116.237834, 23.689628] //中心点坐标

      });
      marker = new AMap.Marker({
        position: new AMap.LngLat(116.237834, 23.689628),
        // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: '北京'
      }); //构建信息窗体中显示的内容

      var info = [];
      info.push("<div class='input-card content-window-card'> ");
      info.push("<div style=\"padding:7px 0px 0px 0px;\"><h4>精易科技有限公司</h4>"); // info.push("<p class='input-item'>电话 : 010-84107000   邮编 : 100102</p>");

      info.push("<p class='input-item'>地址 :广东省揭阳市揭东区玉湖镇玉湖雅苑5栋4梯E205</p></div></div>");
      infoWindow = new AMap.InfoWindow({
        anchor: 'top-center',
        position: [116.237834, 23.689628],
        content: info.join("") //使用默认信息窗体框样式，显示信息内容

      });
      infoWindow.open(map, map.getCenter());
      map.add(marker);
      marker.on('click', this.showInfoM);
    },

    showInfoM() {
      infoWindow.open(map, map.getCenter());
    }

  }
};