import { ref } from 'vue';
export default {
  data() {
    return {// siteInfo: window.siteInfo
    };
  },

  props: {
    activeIndex: {
      type: [String, Number],
      default: () => {
        return 0;
      }
    },
    siteInfo: {
      type: Object
    }
  },
  watch: {
    activeIndex: {
      handler(val) {
        this.$emit('change', val);
      },

      immediate: true
    },
    siteInfo: {
      handler(val) {},

      immediate: true
    }
  },
  setup: () => {
    var ref_leftList = [];

    if (window.domain == 'www.ezu.la') {
      ref_leftList = [{
        name: "首页",
        link: "/"
      }, {
        name: "合伙人计划",
        link: "/partner"
      }, {
        name: "校园外卖",
        link: "/school-take-out"
      }, {
        name: "校园跑腿",
        link: "/school-run"
      }, {
        name: "校园帮",
        link: "/school-help"
      }, {
        name: "站长案例",
        link: "/master-case"
      }, {
        name: "关于我们",
        link: "/about-us"
      }, {
        name: "用户体验",
        link: "/download-app"
      }, {
        name: "站长申请",
        link: "/"
      }];
    } else {
      ref_leftList = [{
        name: "　　　　",
        link: "/"
      }, {
        name: "　　　　　　　　　　　　",
        link: "/"
      }, {
        name: "　　　　　　　　　　　　",
        link: "/"
      }, {
        name: "　　　　　　　　　　　　",
        link: "/"
      }];
    }

    let leftList = ref(ref_leftList);
    leftList._rawValue = [{
      name: '首页',
      link: '/'
    }]; // # child 变量名 需要同 组件上的ref 名称相同

    const application = ref();

    const showDialog = name => {
      // 调用子组件的方法
      // # 需要子组件通过setup 暴露 childMethod 方法
      if (name == '站长申请') {
        application.value.showDialog();
      }
    }; // # 原理 就是 通过ref 能直接访问 子组件dom
    // # 子组件暴露的方法 能从dom中直接调用
    // # 暴露子组件和 handleClick 方法


    return {
      leftList,
      application,
      showDialog
    };
  }
};