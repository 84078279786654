import WOW from "wow.js";
export default {
  data() {
    return {
      TabList: ['迪想校园用户端', '迪想校园商家端', '迪想校园骑手端'],
      imageList: [require('../static/common/buyer.png'), require('../static/common/business.png'), require('../static/common/rider.png')],
      activeIndex: 0
    };
  },

  mounted() {
    let wow = new WOW({
      boxClass: 'wow',
      //需要执行动画元素的Class
      animateClass: 'animated',
      //animation.css动画的Class
      offset: 0,
      //距离可视区域多少开始执行动画
      mobile: true,
      //是否在移动设备执行动画
      live: true //异步加载的内容是否有效

    });
    wow.init();
  },

  methods: {
    mouseoverTab(index) {
      console.log("index", index);
      this.activeIndex = index;
    }

  }
};