import WOW from "wow.js";
export default {
  mounted() {
    let wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true //异步加载的内容是否有效

    });
    wow.init();
  },

  data() {
    return {
      siteInfo: window.siteInfo,
      content1List: [{
        title: "帮买模式",
        desList: ["生活百货", "生鲜食品", "烟酒", "咖啡", "药品", "......"],
        image: require("../../static/school-run/content-1/1.png")
      }, {
        title: "帮送模式",
        desList: ["紧急物品", "文件证件", "鲜花鸡蛋", "3C数码", "钥匙", "......"],
        image: require("../../static/school-run/content-1/2.png")
      }, {
        title: "帮取模式",
        desList: ["快递包裹", "生活用品", "办公文具", "药品票据", "......"],
        image: require("../../static/school-run/content-1/3.png")
      }, {
        title: "帮排队模式",
        desList: ["办事派对", "报名排队", "抢购排队", "......"],
        image: require("../../static/school-run/content-1/4.png")
      }],
      content4List: [{
        image: require("../../static/school-run/content-2/1.png"),
        title: "河北建筑工程学院",
        des: "坐落在被誉为“第二延安”和“长城博物馆”美称的冬奥名城河北省张家口市，是河北省唯一以培养土木建筑类专业人才为主的公办普通高校。"
      }, {
        image: require("../../static/school-run/content-2/2.png"),
        title: "河北建筑工程学院",
        des: "坐落在被誉为“第二延安”和“长城博物馆”美称的冬奥名城河北省张家口市，是河北省唯一以培养土木建筑类专业人才为主的公办普通高校。"
      }, {
        image: require("../../static/school-run/content-2/3.png"),
        title: "河北建筑工程学院",
        des: "坐落在被誉为“第二延安”和“长城博物馆”美称的冬奥名城河北省张家口市，是河北省唯一以培养土木建筑类专业人才为主的公办普通高校。"
      }, {
        image: require("../../static/school-run/content-2/4.png"),
        title: "河北建筑工程学院",
        des: "坐落在被誉为“第二延安”和“长城博物馆”美称的冬奥名城河北省张家口市，是河北省唯一以培养土木建筑类专业人才为主的公办普通高校。"
      }],
      content5List: [{
        image: require("../../static/school-run/content-5/1.png"),
        title: "1.下单",
        des: "填写帮买/帮送跑腿需求"
      }, {
        image: require("../../static/school-run/content-5/2.png"),
        title: "2.付款",
        des: "在线支付跑腿费用"
      }, {
        image: require("../../static/school-run/content-5/3.png"),
        title: "3.接单",
        des: "跑腿骑手接单"
      }, {
        image: require("../../static/school-run/content-5/4.png"),
        title: "4.配送/服务",
        des: "订单状态实时更新"
      }, {
        image: require("../../static/school-run/content-5/5.png"),
        title: "5.完成/评价",
        des: "订单评价与完成"
      }]
    };
  }

};