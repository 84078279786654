import WOW from "wow.js";
export default {
  props: {
    type: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      image: require('../static/school-take-out/banner.png'),
      title: '校园外卖',
      des: '聚合食堂窗口或周边餐厅，为本校师生提供快捷的微信+小程序的外卖送餐、到店自取服务'
    };
  },

  created() {
    if (this.type == 2) {
      this.image = require('../static/school-run/banner.png');
      this.title = '校园跑腿';
      this.des = '帮买/帮送/帮排队/帮取/自定义模式为配送、跑腿团队提供完整的解决方案';
    } else if (this.type == 3) {
      this.image = require('../static/school-help/banner.png');
      this.title = '校园帮';
      this.des = '为全校师生及周边商户/企业提供信息发布渠道让同学们获取校园周边信息更加便捷可靠';
    }
  },

  mounted() {
    let wow = new WOW({
      boxClass: 'wow',
      //需要执行动画元素的Class
      animateClass: 'animated',
      //animation.css动画的Class
      offset: 0,
      //距离可视区域多少开始执行动画
      mobile: true,
      //是否在移动设备执行动画
      live: true //异步加载的内容是否有效

    });
    wow.init();
  },

  methods: {
    mouseoverTab(index) {
      console.log("index", index);
      this.activeIndex = index;
    }

  }
};