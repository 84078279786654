import WOW from "wow.js";
export default {
  data() {
    return {
      siteInfo: window.siteInfo,
      blockList: [{
        title: '商家入驻',
        describe: '我是商家我要入驻',
        image: require('../../static/index/image-top/block-1.png')
      }, {
        title: '站点入驻',
        describe: '成为校园合伙人',
        image: require('../../static/index/image-top/block-2.png')
      }, {
        title: '骑手注册',
        describe: '我想兼职/注册配送员',
        image: require('../../static/index/image-top/block-3.png')
      }],
      funLeftList: [{
        image: require('../../static/index/content-1/1.png'),
        name: '外卖到寝'
      }, {
        image: require('../../static/index/content-1/2.png'),
        name: '跑腿办事'
      }, {
        image: require('../../static/index/content-1/3.png'),
        name: '生活信息'
      }, {
        image: require('../../static/index/content-1/4.png'),
        name: '校园超市'
      }, {
        image: require('../../static/index/content-1/5.png'),
        name: '优选商城'
      }, {
        image: require('../../static/index/content-1/6.png'),
        name: '二手市场'
      }],
      sceneList: [{
        image: require('../../static/index/content-2/1.png'),
        name: '校园外卖',
        des: '聚合食堂窗口或周边餐厅，为本校师生提供快捷的公众号+小程序的外卖送餐、到店自取服务'
      }, {
        image: require('../../static/index/content-2/2.png'),
        name: '快递代取',
        des: '代取快递/代买/代办或集中化的外卖配送，即可人人参与众包跑腿，也可统一管理快捷专送'
      }, {
        image: require('../../static/index/content-2/3.png'),
        name: '寻物启事',
        des: '一物一码智能防丢/溯源解决方案，为广大用户提供基本隐私保护的二维码防丢卡贴/挂件/吊牌等'
      }],
      schoolBazaarList: [{
        image: require('../../static/index/content-3/1.png'),
        name: '校园外卖',
        des: '高校是特殊的需求市场，每年在全国有数千万的客户群体，客源稳定，需求量大。高校餐厅及周边商家可通过平台提供校园外卖配送，彻底解决“最后一公里”的问题。'
      }, {
        image: require('../../static/index/content-3/2.png'),
        name: '校园跑腿',
        des: '帮买/帮送/帮排队/帮取/自定义模式为配送、跑腿团队提供完整的解决方案'
      }, {
        image: require('../../static/index/content-3/3.png'),
        name: '寻物启事',
        des: '为全校师生及周边商户/企业提供信息发布渠道让同学们获取校园周边信息更加便捷市靠'
      }],
      selectDXList: [{
        image: require('../../static/index/content-4/1.png'),
        name: '正规专业',
        des: '多年开发经验，强大的技术开发团队，诸多核心功能优势'
      }, {
        image: require('../../static/index/content-4/2.png'),
        name: '数据安全加密',
        des: '平台数据严谨加密处理，为客户做好安全护盾'
      }, {
        image: require('../../static/index/content-4/3.png'),
        name: '政策扶持',
        des: '免费提供技术支持，系统持续迭代升级'
      }, {
        image: require('../../static/index/content-4/4.png'),
        name: '精细化服务',
        des: '跟踪式教学服务全阶段精细化运营指导'
      }, {
        image: require('../../static/index/content-4/5.png'),
        name: '稳定的服务器',
        des: '服务器稳定保障，前后端分离，APP端采用纯原生开发，提升速度。'
      }, {
        image: require('../../static/index/content-4/6.png'),
        name: '支持多城市多站点',
        des: '帮助平台扩展多城市业务，分站独立运营，平台统一管理、结算'
      }, {
        image: require('../../static/index/content-4/7.png'),
        name: '成本低',
        des: '按需付费，业务模块选择性购买，满足各类场景。'
      }, {
        image: require('../../static/index/content-4/8.png'),
        name: '支持定制开发',
        des: '针对不同客户的运营模式需求，定制专属品牌方案'
      }],
      dialogVisible: false
    };
  },

  mounted() {
    let wow = new WOW({
      boxClass: 'wow',
      //需要执行动画元素的Class
      animateClass: 'animated',
      //animation.css动画的Class
      offset: 0,
      //距离可视区域多少开始执行动画
      mobile: true,
      //是否在移动设备执行动画
      live: true //异步加载的内容是否有效

    });
    wow.init();
  },

  methods: {
    showDialog() {
      this.dialogVisible = true;
    },

    hideDialog() {
      this.dialogVisible = false;
    }

  }
};